window.SingleStatistic = (goal, valueType) => {
	return {
		value: 0, //initial value
		displayValue: '0', //value as a string formated to standard us-en with commas
		goalValue: Number(goal), //ACF value entered in the block for the stat
		// the initial duration of the increment function, starts small (fast) but when the value hits 90% of the goal value it increases (slows down)
		finalDisplayValue: '',
		duration: 3, //time between increments in ms (changed by incrementValue function)
		modifier: 100, //the amount that incrementStep is divided by dictating the size of the increment (changed by incrementValue function)

		// On init, knocks the first 3-6 place values off the end of the number to be replaced with K (thousand) or M (million) on the front end. This is to prevent the number from being too long and breaking the layout.
		init() {
			if (valueType === 'money') {
				if (this.goalValue > 999999) {
					this.goalValue = Math.round(this.goalValue) / 1000000;
				}
				if (this.goalValue > 999) {
					this.goalValue = Math.round(this.goalValue) / 1000;
				}
			} else {
				if (this.goalValue > 999999) {
					this.goalValue = Math.round(this.goalValue) / 1000000;
				}
				if (this.goalValue > 9999) {
					this.goalValue = Math.round(this.goalValue) / 1000;
				}
			}
			this.finalDisplayValue = this.goalValue.toLocaleString('en-US');
		},

		//incrementValue function is called on entering viewport with x-intersect. If the value is less than the goal value, increment the value and run the function again. If its greated than the goal value display the goal value and end the function.
		incrementValue() {
			let incrementStep = Math.ceil(this.goalValue / (this.duration * this.modifier));

			if (this.value < this.goalValue) {
				this.value += incrementStep;
				// when the value reaches 90% of the goal value, increase the duration between steps (slow it down) and decrease the modifier which deceases the size of the incrementStep (show more accurate numbers)
				if (Math.floor(this.value / this.goalValue > 0.9)) {
					this.duration = 100;
					this.modifier = 1;
				}
				this.displayValue = this.value.toLocaleString('en-US');
				setTimeout(() => this.incrementValue(incrementStep), this.duration);
			} else {
				this.value = this.goalValue;
				this.displayValue = this.value.toLocaleString('en-US');
			}
		},
	};
};
