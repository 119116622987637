window.OrganizationsByCohort = (arrayLength) => {
	return {
		currentIndex: 0,
		lastIndex: arrayLength - 1,
		// init() {
		// 	window.setInterval(() => {this.next()},5000)
		// },
		next() {
			this.currentIndex == this.lastIndex ? this.currentIndex=0 : this.currentIndex++
		},
		prev() {
			this.currentIndex == 0 ? this.currentIndex=this.lastIndex : this.currentIndex--
		},
	}
};
