window.AllSponsors = () => {
	return {
		swiper: null,
		init() {
			this.swiper = new Swiper(this.$refs.swiperContainer, {
				loop: true,
				slidesPerView: 1,
				spaceBetween: 0,
				autoplay: {
					delay: 3000,
				},
				breakpoints: {
					640: {
						slidesPerView: 1,
						spaceBetween: 0,
					  },
					  768: {
						slidesPerView: 2,
						spaceBetween: 0,
					  },
					  1024: {
						slidesPerView: 3,
						spaceBetween: 0,
					  },
					  1280: {
						slidesPerView: 4,
						spaceBetween: 0,
					  },
				},
				navigation: {
					nextEl: this.$refs.swiperNext,
					prevEl: this.$refs.swiperPrev,
				},
			})
		}
	};
};
